<!--Display last 200 articles-->
<div class="administrator-articles">
  <div class="page-title">
    <h1>Članci</h1>
  </div>
  <div class="bottom">
    <div class="filters">
<!--      <div class="article-type">-->
<!--        <button (click)="getActive('active')"-->
<!--                [disabled]="selectedFilter == 'active'">-->
<!--          Aktivni-->
<!--        </button>-->
<!--        <button (click)="getDelete('inactive')"-->
<!--                [disabled]="selectedFilter == 'inactive'">-->
<!--          Uklonjeni-->
<!--        </button>-->
<!--      </div>-->
      <div class="articles-sort-by">
        <label for="userFilter">Filter by User:</label>
        <select id="userFilter" [(ngModel)]="selectedEditor" (change)="filterByUser()">
          <option value="">All Users</option>
          <option *ngFor="let user of editors" [value]="user._id">{{ user.name }}</option>
        </select>
      </div>
      <div class="articles-sort-time">
        <label for="timeFilter">Group by:</label>
        <select id="timeFilter" [(ngModel)]="groupBy" (change)="groupByTime()">
          <option value="day">Day</option>
          <option value="month">Month</option>
        </select>
      </div>

    </div>

    <div class="wrapper-temp">
      <div class="articles-group" *ngFor="let group of groupedArticles | keyvalue" (window:scroll)="onScroll()">
        <h2>{{ group.key }}</h2>
        <div class="article-grid">
          <div class="article" *ngFor="let article of group.value">
            <img [src]="article.image_list?.[0]?.url" alt="{{ article.title }}" />
            <h3>{{ article.title }}</h3>
            <div class="overlay">
              <button [routerLink]="article[ArticlesProperty.id]" class="edit-button">
                <i class="fa fa-pencil"></i>
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  <div class="list" *ngIf="this.displayArticles" (window:scroll)="onScroll()">-->
<!--    <div class="articles" *ngFor="let article of displayArticles">-->
<!--      <img src="{{ article.image_list?.[0]?.url }}">-->
<!--      <p>{{article.title}}</p>-->
<!--      <div class="overlay">-->
<!--        <button [routerLink]="'/chair/edit/' + article[ArticlesProperty.id]" class="edit-button">-->
<!--          <i class="fa fa-pencil"></i>-->
<!--        </button>-->
<!--        <button class="remove-button" (click)="deleteArticle(article)">-->
<!--          <i class="fa fa-remove"></i>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
