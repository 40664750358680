<!--Display last 200 articles-->
<div class="administrator-highlights">
  <div class="page-title">
    <h1>Highlight</h1>
  </div>

  <div class="list" *ngIf="this.articles">
    @for (prio of counter(6); track prio; let i = $index){
      <div class="priority">
        <h2>Priority {{i + 1}}</h2>
        @for (article of articles; track article){
          @if(article.priority === i + 1){
            <div class="article">
              <img src="{{ article.image_list?.[0]?.url }}">
              <p>{{article.title}}</p>
              <div class="overlay">
                <button class="remove-button" (click)="removeArticlePriority(article)">
                  <i class="fa fa-remove"></i>
                </button>
              </div>
            </div>
          }

        }
      </div>
    }
<!--    <div class="articles" *ngFor="let article of displayArticles">-->
<!--      <img src="{{ article.image_list?.[0]?.url }}">-->
<!--      <p>{{article.title}}</p>-->
<!--      <div class="overlay">-->
<!--        <button [routerLink]="'/chair/edit/' + article[ArticlesProperty.id]" class="edit-button">-->
<!--          <i class="fa fa-pencil"></i>-->
<!--        </button>-->
<!--        <button class="remove-button" (click)="deleteArticle(article)">-->
<!--          <i class="fa fa-remove"></i>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
