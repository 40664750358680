<div class="options">
  <img src="assets/zabavnojeznati-spica.gif">

  <button class="option"
          [ngClass]="{'selected': answer === 1}"
          (click)="submitAnswer(1)">1</button>
  <button class="option"
          [ngClass]="{'selected': answer === 2}"
          (click)="submitAnswer(2)">2</button>
  <button class="option"
          [ngClass]="{'selected': answer === 3}"
          (click)="submitAnswer(3)">3</button>
  <button class="option"
          [ngClass]="{'selected': answer === 4}"
          (click)="submitAnswer(4)">4</button>
</div>
