<div class="answer">
  <video autoplay muted loop @fadeInOut>
    <source src="assets/loop_logo.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  @if(answer === 1 && display){
    <video autoplay muted loop @fadeInOut>
      <source src="assets/LOOP_1.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }
  @else if(answer === 2 && display){
    <video autoplay muted loop @fadeInOut>
      <source src="assets/LOOP_2.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }
  @else if(answer === 3 && display){
    <video autoplay muted loop @fadeInOut>
      <source src="assets/LOOP_3.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }
  @else if(answer === 4 && display){
    <video autoplay muted loop @fadeInOut>
      <source src="assets/LOOP_4.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }
</div>
