<div class="footer">
  <div class="categories">
    @for(category of categories; track categories){
      <button [routerLink]="category[categoriesProperty.category_url]">
        {{category[categoriesProperty.name]}}
      </button>
    }
  </div>
  <div class="line"></div>
  <div class="hayat">
    <img src="assets/logo-h.webp"
         alt="Hayat icon"
         style="position: relative"
    >
    <div class="links">
      <div class="website">
        <button [routerLink]="">
          Webportal
        </button>
        <button [routerLink]="'televizija/gledaj'">
          Live TV
        </button>
      </div>
      <div class="horizontal-line">
      </div>
      <div class="social">
        <a href="https://www.facebook.com/HayatMediaBIH" target="_blank" class="social-icon">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.instagram.com/hayatmediabih/" target="_blank" class="social-icon">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://twitter.com/HayatMediaBIH" target="_blank" class="social-icon">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="https://www.youtube.com/@HayatMediaBIH" target="_blank" class="social-icon">
          <i class="fab fa-youtube"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="formal">
    <button [routerLink]="'uslovi-koristenja'">
      Opšti uslovi korištenja
    </button>
    <!--<button [routerLink]="'uslovi-koristenja'">
      Marketing
    </button>-->
    <button [routerLink]="'impressum'">
      Impressum
    </button><button [routerLink]="'marketing'">
      Marketing
    </button>
    <button [routerLink]="'contact'">
      Kontakt
    </button>
  </div>
  <div class="signature">
    © Hayat.ba Sva prava zadržana.
  </div>
</div>
