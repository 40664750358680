import {Component, OnInit} from '@angular/core';
import {Articles} from "../../../models/articles.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ArticlesService} from "../../../services/articles.service";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {ConfirmationDialogComponent} from "../../dialogs/confirmation-dialog/confirmation-dialog.component";
import {RouterLink} from "@angular/router";
import {KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import { KeyDescOrderPipe } from '../../../pipes/key-articles-order.pipe';
import {Chair} from "../../../models/chair.model";
import {EditorsServices} from "../../../services/editors.services";


@Component({
  selector: 'app-administrator-requests',
  templateUrl: './administrator-requests.component.html',
  standalone: true,
  imports: [
    RouterLink,
    NgForOf,
    NgIf,
    FormsModule,
    KeyValuePipe,
  ],
  styleUrls: ['./administrator-requests.component.css']
})
export class AdministratorRequestsComponent implements OnInit{
  articles!: Articles[];
  deletedArticles!: Articles[];

  displayArticles!: Articles[];
  groupedArticles: { [key: string]: Articles[] } = {};


  editors!: Chair[];
  selectedEditor: string = '';  // For user filtering


  groupBy: string = 'day';    // For grouping by day or month


  private page = 1;
  private articlesPerPage = 15;
  private newArticlesLoading: boolean = false
  private noMoreArticles: boolean = false

  selectedFilter: string = 'active';

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private articlesService: ArticlesService,
    private editorsServices: EditorsServices,

  ) {
  }
  ngOnInit(): void {
    this.articlesService.getAllRequests(1).subscribe(
      (articles: Articles[]) => {
        this.articles = articles; // Assign the retrieved courses to the courses property
        this.displayArticles = articles; // Assign the retrieved courses to the courses property
        if (this.groupBy === 'day') {
          this.groupArticlesByDay(this.articles);
        } else if (this.groupBy === 'month') {
          this.groupArticlesByMonth(this.articles);
        }

        if(articles.length < this.articlesPerPage && this.selectedFilter === 'active')
        {
          this.noMoreArticles = true;
        }
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting editors:', error);
      }
    );
    // this.articlesService.getDeletedArticles(1).subscribe(
    //   (articles: Articles[]) =>{
    //     this.deletedArticles = articles; // Assign the retrieved courses to the courses property
    //     if(articles.length < this.articlesPerPage && this.selectedFilter === 'inactive')
    //     {
    //       this.noMoreArticles = true;
    //     }
    //   }
    // )
    this.editorsServices.getEditors().subscribe(
      (editors: Chair[]) => {
        this.editors = editors; // Assign the retrieved courses to the courses property
        console.log(editors)
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting editors:', error);
      });
  }

  groupByTime(): void {
    this.groupedArticles = {};
    if (this.groupBy === 'day') {
      this.groupArticlesByDay(this.articles);
    } else if (this.groupBy === 'month') {
      this.groupArticlesByMonth(this.articles);
    }
  }

  groupArticlesByDay(newArticles: Articles[] = []): void {
    newArticles.forEach((article: Articles) => {
      const date = new Date(article.request_date);
      const dayMonthYear = `${date.getFullYear()} ${date.toLocaleString('default', { month: 'long' })} ${String(date.getDate()).padStart(2, '0')}`;

      // By day
      if (!this.groupedArticles[dayMonthYear]) {
        this.groupedArticles[dayMonthYear] = [];
      }
      this.groupedArticles[dayMonthYear] = [...this.groupedArticles[dayMonthYear], article];
    });

    // Sort the groupedArticles keys (month-year) in descending order
    this.groupedArticles = Object.keys(this.groupedArticles)
      .sort((a, b) => {
        const dateA = new Date(a).getTime(); // Parse the date part from key
        const dateB = new Date(b).getTime();
        return dateB - dateA; // Descending order
      })
      .reduce((acc: any, key: string) => {
        acc[key] = this.groupedArticles[key];
        return acc;
      }, {});

    console.log(this.groupedArticles)
  }
  groupArticlesByMonth(newArticles: Articles[] = []): void {
    newArticles.forEach((article: Articles) => {
      const date = new Date(article.request_date);
      const monthYear = `${date.getFullYear()} ${date.toLocaleString('default', { month: 'long' })}`;
      // By day
      if (!this.groupedArticles[monthYear]) {
        this.groupedArticles[monthYear] = [];
      }
      this.groupedArticles[monthYear] = [...this.groupedArticles[monthYear], article];
    });

    // Sort the groupedArticles keys (month-year) in descending order
    this.groupedArticles = Object.keys(this.groupedArticles)
      .sort((a, b) => {
        const dateA = new Date(a).getTime(); // Parse the date part from key
        const dateB = new Date(b).getTime();
        return dateB - dateA; // Descending order
      })
      .reduce((acc: any, key: string) => {
        acc[key] = this.groupedArticles[key];
        return acc;
      }, {});

    console.log(this.groupedArticles)
  }

  onScroll(): void {
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.scrollHeight;
    const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

    const atBottom = windowHeight + scrollPosition >= documentHeight;

    if (atBottom && !this.newArticlesLoading && !this.noMoreArticles) {
      this.newArticlesLoading = true
      this.loadMoreArticles();
    }
  }

  getArticlesByEditor(): void{
    this.articlesService.getRequestedArticlesByEditor(this.selectedEditor, this.page).subscribe(
      (articles: any[]) => {
        console.log(articles)
        this.articles = [...this.articles, ...articles];
        this.displayArticles = [...this.articles, ...articles];

        if (this.groupBy === 'day') {
          this.groupArticlesByDay(articles);
        } else if (this.groupBy === 'month') {
          this.groupArticlesByMonth(articles);
        }

        if(articles.length < this.articlesPerPage)
        {
          this.noMoreArticles = true;
        }
        this.newArticlesLoading = false
      },
      (error) => {
        console.error('Error fetching expired surveys:', error);
      }
    );
  }

  getAllArticles(): void{
    this.articlesService.getAllArticlesByDate(this.page).subscribe(
      (articles: Articles[]) => {
        // Append the new articles to the existing list
        this.articles = [...this.articles, ...articles];
        this.displayArticles = [...this.articles, ...articles];

        if (this.groupBy === 'day') {
          this.groupArticlesByDay(articles);
        } else if (this.groupBy === 'month') {
          this.groupArticlesByMonth(articles);
        }

        if(articles.length < this.articlesPerPage)
        {
          this.noMoreArticles = true;
        }
        this.newArticlesLoading = false
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting more articles:', error);
      }
    );
  }

  loadMoreArticles(): void {
    // Increment the current page
    this.page++;
    console.log(this.selectedEditor)
    if(this.selectedEditor){
      console.log('EDITOR')
      this.getArticlesByEditor()
    }
    else if(this.selectedFilter == 'active')
    {
      this.getAllArticles()
    }
    // else {
    //   this.articlesService.getArchiveArticles(this.page).subscribe(
    //     (articles: Articles[]) => {
    //       // Append the new articles to the existing list
    //       this.deletedArticles = [...this.deletedArticles, ...articles];
    //       this.displayArticles = [...this.deletedArticles, ...articles];
    //
    //       if(articles.length < this.articlesPerPage)
    //       {
    //         this.noMoreArticles = true;
    //       }
    //       this.newArticlesLoading = false
    //     },
    //     (error) => {
    //       this.snackBar.open(error, 'Close', {
    //         duration: 3000,
    //         horizontalPosition: 'center',
    //         verticalPosition: 'bottom',
    //       });
    //       console.error('Error getting more articles:', error);
    //     }
    //   );
    // }


  }


  filterByUser(): void {
    this.page = 1
    this.groupedArticles = {};
    this.getArticlesByEditor()
    // console.log(this.selectedEditor)
    // if (this.selectedEditor) {
    //   // Filter the existing articles by matching the editor with the selected user
    //
    //   this.groupedArticles = {};
    //   if (this.groupBy === 'day') {
    //     this.groupArticlesByDay(filteredArticles);
    //   } else if (this.groupBy === 'month') {
    //     this.groupArticlesByMonth(filteredArticles);
    //   }
    // }
    // else {
    //   if (this.groupBy === 'day') {
    //     this.groupArticlesByDay(this.articles);
    //   } else if (this.groupBy === 'month') {
    //     this.groupArticlesByMonth(this.articles);
    //   }
    // }
  }

  deleteArticle(article: Articles): void {
    if(this.selectedFilter === 'inactive'){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          title: 'Da li ste sigurni da želite izbrisati članak?',
          message: 'Članak će biti izbrisan, i više se neće moci povratiti.',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.articlesService.delete(article[ArticlesProperty.id]).subscribe(
            (response) => {
              // Remove the deleted article from the articles array
              this.articles = this.articles.filter(a => a !== article);
            },
            (error) => {
              // Handle error, e.g., display an error message
              console.error('Error archiving article:', error);
            }
          );
        }
      });
    }
    else{
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          title: 'Da li ste sigurni da želite izbrisati članak?',
          message: 'Članak će privremeno biti izbrisan, a nakon 30 dana se više neće moci povratiti.',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.articlesService.archive(article[ArticlesProperty.id]).subscribe(
            (response) => {
              // Remove the deleted article from the articles array
              this.articles = this.articles.filter(a => a !== article);
            },
            (error) => {
              // Handle error, e.g., display an error message
              console.error('Error archiving article:', error);
            }
          );
        }
      });
    }

  }

  getActive(filter: string) {
    this.selectedFilter = filter
    this.displayArticles = this.articles
    if(this.articles.length < this.articlesPerPage)
    {

      this.noMoreArticles = true;
    }
  }
  getDelete(filter: string) {
    this.selectedFilter = filter
    this.displayArticles = this.deletedArticles
    if(this.deletedArticles.length < this.articlesPerPage)
    {

      this.noMoreArticles = true;
    }
  }

  protected readonly ArticlesService = ArticlesService;
  protected readonly ArticlesProperty = ArticlesProperty;
  protected readonly KeyDescOrderPipe = KeyDescOrderPipe;
}
