import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {QuizComponent} from "../quiz.component";
import {QuizService} from "../../../services/quiz.service";
import {NgClass} from "@angular/common";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-quiz-options',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './quiz-options.component.html',
  styleUrl: './quiz-options.component.css'
})
export class QuizOptionsComponent implements OnInit{

  teamId: string | null = null;
  answer: number = 0;

  private socket: WebSocket | undefined; // Define a WebSocket variable
  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private quizService: QuizService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // Capture the team ID from the URL
    this.teamId = this.route.snapshot.paramMap.get('team');
  }

  submitAnswer(answerOption: number): void {
    if(answerOption === this.answer){
      answerOption = 0
    }
    const data = {
      answerOption
    }
    if (this.teamId) {
      this.quizService.postAnswerOption(this.teamId, data).subscribe(() => {
        this.answer = answerOption; // Update the selected answer
      });
    }
  }

}

