import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'keyDescOrder'
})
export class KeyDescOrderPipe implements PipeTransform {
  transform(value: { [key: string]: any }, ...args: unknown[]): any[] {
    if (!value) return [];

    return Object.keys(value)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
      .map(key => ({ key, value: value[key] }));
  }
}
