<form [formGroup]="chairForm" (ngSubmit)="onSubmit()" class="survey">
  <label for="email">Email:</label>
  <input type="text" formControlName="email" id="email">

  <div class="password-container">
    <label for="password">Password:</label>
    <input
      type="password"
      formControlName="password"
      id="password"
      placeholder="Enter your password"
    />
    <button class="passButton" type="button" (click)="togglePasswordVisibility()">
      <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </div>

  <label for="name">Ime i prezime:</label>
  <input type="text" formControlName="name" id="name">

  <label for="initials">Inicijali:</label>
  <input type="text" formControlName="initials" id="initials">

  <div class="admin-checkbox">
    <mat-checkbox formControlName="isAdmin">Administrator</mat-checkbox>
    <div class="indent">
      <mat-checkbox formControlName="permissionCreateChairs" [disabled]="!this.chairForm.get('isAdmin')?.value">Uredivanje korisnika</mat-checkbox>
      <mat-checkbox formControlName="permissionApproveRequests" [disabled]="!this.chairForm.get('isAdmin')?.value">Odobravanje članaka</mat-checkbox>
    </div>
  </div>

  <div class="editor-checkbox">
    <mat-checkbox formControlName="isEditor">Urednik</mat-checkbox>
    <div class="indent">
      <mat-checkbox formControlName="permissionArticles" [disabled]="!this.chairForm.get('isEditor')?.value">Articles</mat-checkbox>
      <mat-checkbox formControlName="permissionCategories" [disabled]="!this.chairForm.get('isEditor')?.value">Categories</mat-checkbox>
      <mat-checkbox formControlName="permissionSurveys" [disabled]="!this.chairForm.get('isEditor')?.value">Surveys</mat-checkbox>
      <mat-checkbox formControlName="permissionTags" [disabled]="!this.chairForm.get('isEditor')?.value">Tags</mat-checkbox>
      <mat-checkbox formControlName="permissionShows" [disabled]="!this.chairForm.get('isEditor')?.value">Shows</mat-checkbox>
      <mat-checkbox formControlName="sendNotification" [disabled]="!this.chairForm.get('isEditor')?.value">Notifikacije</mat-checkbox>
      <mat-checkbox formControlName="accessAllSignatures" [disabled]="!this.chairForm.get('isEditor')?.value">Svi potpisi</mat-checkbox>
      <mat-checkbox formControlName="permissionIndependently" [disabled]="!this.chairForm.get('isEditor')?.value">Samostalnost</mat-checkbox>
    </div>
  </div>

  <div class="buttons">
    <button type="submit">Update</button>
  </div>
</form>
