<div class="header">
  <div class="navigation">
    <div class="left">
      <button class="logo"
              aria-label="home"
              [routerLink]="'/'">
        <img src="assets/hayat-logo.webp">
      </button>

      <div class="categories">
        <button class="button"
                [routerLink]="'/'"
                [class.selected]="selectedRoute === '/'"
        >
          <span>Dashboard</span>
        </button>
        @if(chair[ChairProperty.permissionArticles]){
          <button class="button"
                  [routerLink]="'upload'"
                  [class.selected]="selectedRoute === 'chair/upload'"
                  (click)="checkUrlCategory()"
          >
            <span>Novi Članak</span>
          </button>
          <button class="button"
                  [routerLink]="'articles'"
                  [class.selected]="selectedRoute === 'chair/articles'"
                  (click)="setRoute('articles')"
          >
            <span>Članci</span>
          </button>
        }
        @if(chair[ChairProperty.permissionCategories]){
          <button class="button"
                  *ngIf="chairIsAdmin()"
                  [routerLink]="'categories'"
                  [class.selected]="selectedRoute === 'chair/categories'"
                  (click)="checkUrlCategory()"
          >
            <span>Kategorije</span>
          </button>
        }
        @if(chair[ChairProperty.permissionSurveys]){
          <button class="button"
                  [routerLink]="'survey'"
                  [class.selected]="selectedRoute === 'chair/survey'"
                  (click)="checkUrlCategory()"
          >
            <span>Anketa</span>
          </button>
        }

        @if(chair[ChairProperty.permissionTags]){
          <button class="button"
                  [routerLink]="'tags'"
                  [class.selected]="selectedRoute === 'chair/tags'"
                  (click)="checkUrlCategory()"
          >
            <span>Tagovi</span>
          </button>
        }
        @if(chair[ChairProperty.permissionShows]){
          <button class="button"
                  [routerLink]="'shows'"
                  [class.selected]="selectedRoute === 'chair/shows'"
                  (click)="checkUrlCategory()"
          >
            <span>Shows</span>
          </button>
        }
        <button class="button"
                *ngIf="chairIsAdmin()"
                [routerLink]="'guests'"
                [class.selected]="selectedRoute === 'chair/guests'"
                (click)="checkUrlCategory()"
        >
          <span>Guests</span>
        </button>
        <button class="button"
                *ngIf="chairIsAdmin()"
                [routerLink]="'editors'"
                [class.selected]="selectedRoute === 'chair/editors'"
                (click)="checkUrlCategory()"
        >
          <span>Urednici</span>
        </button>
        <button class="button"
                *ngIf="chairIsAdmin()"
                [routerLink]="'administrators'"
                [class.selected]="selectedRoute === 'chair/administrators'"
                (click)="checkUrlCategory()"
        >
          <span>Administratori</span>
        </button>
        @if(chair[ChairProperty.permissionApproveRequests]){
          <button class="button"
                  [routerLink]="'requests'"
                  [class.selected]="selectedRoute === 'chair/requests'"
                  (click)="checkUrlCategory()"
          >
            <span>Zahtjevi</span>
          </button>
        }
      </div>
            <!--<ng-container *ngIf="tokenExists()">
              <button mat-button
                      class="button regular"
                      [routerLink]="'profile'">
                Profile
              </button>
            </ng-container>-->
    </div>



    <div class="right">
      <button
        *ngIf="tokenExists()"
        class="button login"
        (click)="logoutUser()"
      >
        <p>Log out</p>
      </button>
    </div>
  </div>
</div>
