export class Route {
  public static ADMINISTRATOR = 'administrator';
  public static CATEGORY = ':category';
  public static EMPTY = '';
  public static SURVEY = 'survey';
  public static LOGIN = 'login';
  public static SIGNUP = 'signup';
  public static CHAIR = 'chair';
  public static ADMIN = 'admin';
  public static GUEST = 'guest';
  public static USER = 'user';
  public static ADMINISTRATORS = 'administrators';
  public static LIVETV = 'live-tv';
  public static ARCHIVE = 'videoteka';
  public static GLEDAJ = 'gledaj';
  public static TV = 'televizija';
  public static SHOWS = 'shows';
  public static EPISODE = 'episode';
  public static SEARCH = 'search';
  public static TAG = ':tag';
  public static ID = ':id';
  public static GUESTS = 'guests';
  public static ABOUTUS = 'o-nama';
  public static TERMSOFUSE = 'uslovi-koristenja';
  public static ABOUT = 'about';
  public static CHAT = 'chat';

  public static QA = 'q&a';
  public static ARTICLE = 'article';
  public static ARTICLES = 'articles';
  public static HIGHLIGHTS = 'highlights';
  public static CATEGORIES = 'categories';
  public static CONTACT = 'contact';
  public static DRAFT = 'draft';
  public static EDIT = 'edit';
  public static EDITORS = 'editors';
  public static SEPARATOR = '/';
  public static USLOVI = 'uslovi';
  public static ORDER = ':order';
  public static IMPRESSUM = 'impressum';
  public static REQUESTS = 'requests';
  public static PAGE = ':page';
  public static PROFILE = 'profile';
  public static NEWS = 'news';
  public static SCHEDULE = 'schedule';
  public static UPLOAD = 'upload';
  public static TAGS = 'tags';
  public static MARKETING = 'marketing';
  public static TYPE = ':type';
  public static TITLE = ':title';
  public static DELETE = 'delete';


  public static QUIZ = 'zabavnojeznati';
  public static ANSWER = 'answer';
  public static CONTROL = 'control';
  public static OPTIONS = 'options';
  public static TEAM = ':team';

}
