<div class="marketing">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0001-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0002-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0003-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0004-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0005-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0006-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0007-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0008-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0009-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0010-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0011-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0012-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0013-min.jpg">
  <img src="assets/marketing/CJENOVNIK%20HAYATMEDIABIH%202024._page-0014-min.jpg">
</div>
