import {ChangeDetectorRef, Component} from '@angular/core';
import {QuizOptionsComponent} from "../quiz-options/quiz-options.component";
import {Subscription, switchMap, timer} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {QuizService} from "../../../services/quiz.service";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-quiz-control',
  standalone: true,
  imports: [
    QuizOptionsComponent
  ],
  templateUrl: './quiz-control.component.html',
  styleUrl: './quiz-control.component.css',
})
export class QuizControlComponent {
  answer1: number = 0;
  answer2: number = 0;

  display: boolean = false;

  private socket: WebSocket | undefined; // Define a WebSocket variable
  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private quizService: QuizService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // Capture the team ID from the URL
    this.subscriptions = this.route.params.subscribe((params) => {
      {
        this.quizService.listenForEventUpdates('1').subscribe(
          (result: any) => {
            // this.answer = result.answerOption;
            this.answer1 = result.answerOption
            this.cdr.detectChanges();
          },
          (error: any) => {
            // Handle error
            console.error('Error fetching event data:', error);
          }
        );

        this.quizService.listenForEventUpdates('2').subscribe(
          (result: any) => {
            // this.answer = result.answerOption;
            this.answer2 = result.answerOption
            this.cdr.detectChanges();
          },
          (error: any) => {
            // Handle error
            console.error('Error fetching event data:', error);
          }
        );
      }
    });
  }

  displayAnswers(): void{
    const data = {
      display: this.display!
    }
    this.quizService.postDisplayState(data).subscribe(() => {
      this.display = this.display!; // Update the selected answer
    });
  }

}
