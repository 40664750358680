import {Component} from '@angular/core';
import {Articles} from "../../../models/articles.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ArticlesService} from "../../../services/articles.service";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {ConfirmationDialogComponent} from "../../dialogs/confirmation-dialog/confirmation-dialog.component";
import {RouterLink} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {response} from "express";

@Component({
  selector: 'app-administrator-articles',
  templateUrl: './administrator-highlights.component.html',
  standalone: true,
    imports: [
        RouterLink,
        NgForOf,
        NgIf,
        FormsModule
    ],
  styleUrls: ['./administrator-highlights.component.css']
})
export class AdministratorHighlightsComponent {
  articles!: Articles[];
  deletedArticles!: Articles[];

  displayArticles!: Articles[];

  private page = 1;
  private articlesPerPage = 15;
  private newArticlesLoading: boolean = false
  private noMoreArticles: boolean = false

  selectedFilter: string = 'active';

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private articlesService: ArticlesService,
  ) {
  }

  counter(i: number) {
    return new Array(i);
  }
  ngOnInit(): void {
    this.articlesService.getAllArticlesByPriority(1).subscribe(
      (articles: Articles[]) => {
        this.articles = articles; // Assign the retrieved courses to the courses property
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting articles:', error);
      }
    );
  }


  removeArticlePriority(article: Articles): void {
    // FUNCTION TO REMOVE ARTICLE PRIORITY
    this.articlesService.removePriority(article[ArticlesProperty.id]).subscribe(
      (response) =>{
        this.articles = this.articles.filter(a => a !== article);
      }
    )

  }

  protected readonly ArticlesService = ArticlesService;
  protected readonly ArticlesProperty = ArticlesProperty;
}
