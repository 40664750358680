import {RouterModule, Routes} from '@angular/router';
import {Route} from "./constants/route.constants";
import {ChairGuard} from "./constants/admin.guard";
import {AdministratorMainComponent} from "./components/administrator/administrator-main/administrator-main.component";
import {
  AdministratorDashboardComponent
} from "./components/administrator/administrator-dashboard/administrator-dashboard.component";
import {UploadComponent} from "./components/upload/upload.component";
import {
  AdministratorArticlesComponent
} from "./components/administrator/administrator-articles/administrator-articles.component";
import {
  AdministratorEditorsComponent
} from "./components/administrator/administrator-editors/administrator-editors.component";
import {
  AdministratorChairsComponent
} from "./components/administrator/administrator-chairs/administrator-chairs.component";
import {
  AdministratorCategoriesComponent
} from "./components/administrator/administrator-categories/administrator-categories.component";
import {
  AdministratorSurveyComponent
} from "./components/administrator/administrator-survey/administrator-survey.component";
import {AdministratorTagComponent} from "./components/administrator/administrator-tag/administrator-tag.component";
import {
  AdministratorLoginComponent
} from "./components/account/login/administrator-login/administrator-login.component";
import {LoginComponent} from "./components/account/login/login.component";
import {SignupComponent} from "./components/account/signup/signup.component";
import {MainComponent} from "./components/main/main.component";
import {ResolverResponse} from "./constants/resolver-response.constants";
import {PrivacyPolicyComponent} from "./common/privacy-policy/privacy-policy.component";
import {HomeComponent} from "./components/webportal/home/home.component";
import {ArticlesMainResolver} from "./resolvers/articles-main.resolver";
import {ArticleHighlightResolver} from "./resolvers/article-highlight.resolver";
import {SurveyComponent} from "./components/survey/survey.component";
import {ArticleComponent} from "./components/webportal/article/article.component";
import {ArticleResolver} from "./resolvers/article.resolver";
import {CategoryComponent} from "./components/webportal/category/category.component";
import {ArticlesResolver} from "./resolvers/articles.resolver";
import {CategoryResolver} from "./resolvers/category.resolver";
import {ArticleContainerComponent} from "./components/webportal/article/containers/article-container.component";
import {UploadContainerComponent} from "./components/upload/containers/upload-container.component";
import {HayatTvComponent} from "./components/hayat-tv/hayat-tv.component";
import {OptionsComponent} from "./components/hayat-tv/gledaj-hayat/options/options.component";
import {VideotekaComponent} from "./components/hayat-tv/gledaj-hayat/videoteka/videoteka.component";
import {LiveTvComponent} from "./components/hayat-tv/gledaj-hayat/live-tv/live-tv.component";
import {AboutHayatComponent} from "./components/hayat-tv/about-hayat/about-hayat.component";
import {
  ServiceGledajHayatComponent
} from "./components/hayat-tv/about-hayat/service-gledaj-hayat/service-gledaj-hayat.component";
import {ServiceGledajHayatGuideComponent} from "./components/hayat-tv/about-hayat/service-gledaj-hayat-guide/service-gledaj-hayat-guide.component";
import {ChannelsInfoComponent} from "./components/hayat-tv/about-hayat/channels-info/channels-info.component";
import {SmartTvGuideComponent} from "./components/hayat-tv/about-hayat/smart-tv-guide/smart-tv-guide.component";
import {TermsOfUseComponent} from "./components/webportal/formal/terms-of-use/terms-of-use.component";
import {ContactComponent} from "./components/webportal/formal/contact/contact.component";
import {ImpressumComponent} from "./components/webportal/formal/impressum/impressum.component";
import {ShowsComponent} from "./components/hayat-tv/shows/shows.component";
import {AllShowsComponent} from "./components/hayat-tv/shows/all-shows/all-shows.component";
import {OneShowComponent} from "./components/hayat-tv/shows/one-show/one-show.component";
import {ShowAboutComponent} from "./components/hayat-tv/shows/one-show/show/show-about/show-about.component";
import {ShowChatComponent} from "./components/hayat-tv/shows/one-show/show/show-chat/show-chat.component";
import {ShowQuestionsComponent} from "./components/hayat-tv/shows/one-show/show-episodes-info/show-questions/show-questions.component";
import {
  AdministratorShowsComponent
} from "./components/administrator/administrator-shows/administrator-shows.component";
import {
  AdministratorShowComponent
} from "./components/administrator/administrator-show/administrator-show.component";
import {
  AdministratorGuestsComponent
} from "./components/administrator/administrator-guests/administrator-guests.component";
import {EpisodeComponent} from "./components/hayat-tv/shows/one-show/episode/episode.component";
import {
  EpisodeAboutComponent
} from "./components/hayat-tv/shows/one-show/episode/episode-about/episode-about.component";
import {
  EpisodeQuestionsComponent
} from "./components/hayat-tv/shows/one-show/episode/episode-questions/episode-questions.component";
import {ShowComponent} from "./components/hayat-tv/shows/one-show/show/show.component";
import {
  AdministratorEpisodeComponent
} from "./components/administrator/administrator-episode/administrator-episode.component";
import {UserLoginComponent} from "./components/account/login/user-login/user-login.component";
import {GuestLoginComponent} from "./components/account/login/guest-login/guest-login.component";
import {SearchComponent} from "./components/webportal/search/search.component";
import {MarketingComponent} from "./components/webportal/formal/marketing/marketing.component";
import {
  AdministratorHighlightsComponent
} from "./components/administrator/administrator-highlights/administrator-highlights.component";
import {
  AdministratorRequestsComponent
} from "./components/administrator/administrator-requests/administrator-requests.component";
import {
  AdministratorRequestReviewComponent
} from "./components/administrator/administrator-request-review/administrator-request-review.component";
import {QuizComponent} from "./components/quiz/quiz.component";
import {QuizAnswersComponent} from "./components/quiz/quiz-answers/quiz-answers.component";
import {QuizOptionsComponent} from "./components/quiz/quiz-options/quiz-options.component";
import {QuizControlComponent} from "./components/quiz/quiz-control/quiz-control.component";

export const routes: Routes = [
  {
    path: Route.CHAIR,
    canActivate: [ChairGuard],
    component: AdministratorMainComponent,
    children: [
      {
        path: Route.EMPTY,
        component: AdministratorDashboardComponent,
      },
      {
        path: Route.UPLOAD,
        component: UploadComponent,
      },
      {
        path: Route.EDIT + Route.SEPARATOR + Route.ID,
        component: UploadContainerComponent,
        resolve: {
          [ResolverResponse.ARTICLE]: ArticleResolver,
        },
      },
      {
        path: Route.ARTICLES,
        component: AdministratorArticlesComponent,
      },
      {
        path: Route.REQUESTS,
        component: AdministratorRequestsComponent,
      },
      {
        path: Route.REQUESTS + Route.SEPARATOR + Route.ID,
        component: AdministratorRequestReviewComponent,
      },
      {
        path: Route.HIGHLIGHTS,
        component: AdministratorHighlightsComponent,
      },
      {
        path: Route.EDITORS,
        component: AdministratorEditorsComponent,
      },
      {
        path: Route.ADMINISTRATORS,
        component: AdministratorChairsComponent,
      },
      {
        path: Route.SHOWS,
        component: AdministratorShowsComponent,
      },
      {
        path: Route.GUESTS,
        component: AdministratorGuestsComponent,
      },
      {
        path: Route.SHOWS + Route.SEPARATOR + Route.ID,
        component: AdministratorShowComponent,
      },
      {
        path: Route.SHOWS + Route.SEPARATOR + Route.ID + Route.SEPARATOR + Route.ORDER,
        component: AdministratorEpisodeComponent,
      },
      {
        path: Route.CATEGORIES,
        component: AdministratorCategoriesComponent,
      },
      {
        path: Route.SURVEY,
        component: AdministratorSurveyComponent,
      },
      {
        path: Route.TAGS,
        component: AdministratorTagComponent,
      },

    ],
  },
  {
    path: Route.QUIZ,
    component: QuizComponent,
    children: [
      {
        path: '',  // Empty path to load by default
        redirectTo: Route.CONTROL, // Redirect to a default child route
        pathMatch: 'full',
      },
      {
        path: Route.CONTROL,
        component: QuizControlComponent,
      },
      {
        path: Route.ANSWER + Route.SEPARATOR + Route.TEAM,
        component: QuizAnswersComponent,
      },
      {
        path: Route.OPTIONS + Route.SEPARATOR + Route.TEAM,
        component: QuizOptionsComponent,
      },
    ],
  },
  {
    path: 'rss',
    redirectTo: Route.EMPTY,
  },
  {
    path: Route.LOGIN,
    component: LoginComponent,
    children: [
      {
        path: '', // Empty path for default route
        redirectTo: Route.USER, // Redirect to /login/user by default
        pathMatch: 'full'
      },
      {
        path: Route.ADMIN,
        component: AdministratorLoginComponent,
      },
      {
        path: Route.USER,
        component: UserLoginComponent,
      },
      {
        path: Route.GUEST,
        component: GuestLoginComponent,
      }
    ]
  },
  {
    path: Route.SIGNUP,
    component: SignupComponent,
  },
  {
    path: Route.TV,
    component: HayatTvComponent,
    children: [
      {
        path: Route.GLEDAJ,
        component: OptionsComponent,
      },
      {
        path: Route.GLEDAJ + Route.SEPARATOR + Route.ARCHIVE,
        component: VideotekaComponent,
        children: [
          {
            path: ':id',
            component: VideotekaComponent,
          }
        ]
      },
      {
        path: Route.GLEDAJ + Route.SEPARATOR + Route.LIVETV,
        component: LiveTvComponent,
      },
      {
        path: Route.SHOWS,
        component: ShowsComponent,
        children: [
          {
            path: Route.EMPTY,
            component: AllShowsComponent,
          },
          {
            path: Route.ID,
            component: OneShowComponent,
            children:[
              {
                path: Route.EMPTY,
                component: ShowComponent,
                children:[
                  {
                    path: Route.EMPTY,
                    component: ShowAboutComponent,
                  },
                  {
                    path: Route.CHAT,
                    component: ShowChatComponent,
                  },
                ]
              },
              {
                path: Route.ORDER,
                component: EpisodeComponent,
                children:[
                  {
                    path: Route.EMPTY,
                    component: EpisodeAboutComponent,
                  },
                  {
                    path: Route.QA,
                    component: EpisodeQuestionsComponent,
                  },
                ]
              },
            ]
          },
        ]
      },
      {
        path: Route.ABOUTUS,
        component: AboutHayatComponent
      },
      {
        path:  Route.ABOUTUS + Route.SEPARATOR + 'servis-gledaj-hayat',
        component: ServiceGledajHayatComponent
      },
      {
        path:  Route.ABOUTUS + Route.SEPARATOR + 'servis-gledaj-hayat-uputstvo',
        component: ServiceGledajHayatGuideComponent
      },
      {
        path:  Route.ABOUTUS + Route.SEPARATOR + 'info-hayat-tv',
        component: ChannelsInfoComponent
      },
      {
        path:  Route.ABOUTUS + Route.SEPARATOR + 'info-smart-tv',
        component: SmartTvGuideComponent
      }

    ]
  },
  {
    path: Route.EMPTY,
    component: MainComponent,
    children: [
      {
        path: Route.USLOVI,
        component: PrivacyPolicyComponent,
      },
      {
        path: Route.CONTACT,
        component: ContactComponent,
      },
      {
        path:  Route.TERMSOFUSE,
        component: TermsOfUseComponent
      },
      {
        path:  Route.IMPRESSUM,
        component: ImpressumComponent
      },
      {
        path:  Route.MARKETING,
        component: MarketingComponent
      },
      {
        path: Route.EMPTY,
        component: HomeComponent,
      },
      {
        path: Route.SURVEY + Route.SEPARATOR + Route.ID,
        component: SurveyComponent,
      },
      {
        path: Route.SEARCH,
        children: [
          {
            path: Route.TAG,
            component: SearchComponent,
          },
        ],
      },
      {
        path: Route.ARTICLE,
        children: [
          {
            path: Route.TITLE,
            children: [
              {
                path: Route.ID,
                component: ArticleContainerComponent,
                resolve: {
                  [ResolverResponse.ARTICLE]: ArticleResolver,
                },
              },
            ]
          },
        ],
      },
      {
        path: Route.CATEGORY,
        component: CategoryComponent,
        resolve: {
          [ResolverResponse.ARTICLES]: ArticlesResolver,
          [ResolverResponse.CATEGORY]: CategoryResolver,
        },
      },
    ],
  },
  {
    path: '**',
    redirectTo: Route.EMPTY,
  },
];

// @NgModule({
//   declarations: [],
//   imports: [
//     RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking', scrollPositionRestoration: "enabled" }),
//     CommonModule
//   ],
//   exports: [RouterModule],
// })
// export class AppRoutingModule {
// }
