import {Component, OnInit} from '@angular/core';
import {Tag} from "../../../../models/tag.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {TagsService} from "../../../../services/tags.services";
import {TagProperty} from "../../../../models/tag-property.enum";
import {NgForOf, SlicePipe} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-home-tags',
  standalone: true,
  imports: [
    NgForOf,
    SlicePipe,
    RouterLink
  ],
  templateUrl: './home-tags.component.html',
  styleUrl: './home-tags.component.css'
})
export class HomeTagsComponent implements OnInit {

  tags!: Tag[];

  constructor(
    private tagsService: TagsService,
  ) {
  }

  ngOnInit(): void {
    this.tagsService.getPopularTags().subscribe(
      (tags: Tag[]) => {
        this.tags = tags
      },
      (error) => {
        /*this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });*/
        console.error('Error getting courses:', error);
      }
    );
  }
}
